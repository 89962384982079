import { ButtonLoader, Notification, ConfirmDialog, TextareaField, Button } from "../../components"
import { Loader } from '../../components/Loader';
import { useState, useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../hooks/UserContext";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { createStoreReport, editStoreData, enqueueReportsExport, fetchStoreInfo, fetchStoreReport, getReportsExport, updateStoreReport } from "../../services/storeServices"
import { CheckCircleIcon, PlayIcon, StopIcon } from '@heroicons/react/outline'
import { LeftCircleOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { DownloadIcon } from "@heroicons/react/solid";
import { SETTINGS_AUTOMATIC_REPORTS, SETTINGS_AUTOMATIC_REPORT_ADD, SETTINGS_AUTOMATIC_REPORT_EDIT, STORE_PATH } from "../../navigation/constants";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom"
import { Redirect, useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { MAIL_REGEX } from "./constants";

export const AutomaticReportDetail = ({
    reportTemplate = null, // Only used when report is not set yet
    onCancel,
    setAutomaticReports
}) => {
    const history = useHistory()
    const { report_id } = useParams()
    const location = useLocation()
    const {
        isLoading,
        isFetching,
        isError,
        isFetchedAfterMount,
        error,
        refetch,
        data: fetchedData,
        isPreviousData
    } = useQuery(['automatic_reports', report_id], () => (report_id ? fetchStoreReport(report_id) : null), {
        keepPreviousData: true,
        onError: (data) => {
            onCancel()
            let updated_path = (STORE_PATH + SETTINGS_AUTOMATIC_REPORTS).replace(":storeId", user.current_store.id)
            history.push(updated_path)
        },
        onSuccess: (data) => {
            if (!isFetchedAfterMount) {
                setFrequency(data?.frequency)
                setSheetHeaders(data?.sheet_headers)
                setRecipientsEmails(data?.recipients_emails)
            }
        }
    })


    const data = report_id ? fetchedData : reportTemplate

    const redirect = () => {
        const path = location.pathname
        if (path.includes(SETTINGS_AUTOMATIC_REPORT_ADD) && !reportTemplate) {
            onCancel()
            let updated_path = (STORE_PATH + SETTINGS_AUTOMATIC_REPORTS).replace(":storeId", user.current_store.id)
            history.push(updated_path)
        }
    }
    useEffect(() => {
        redirect()
    }, [])

    const [frequency, setFrequency] = useState(null)
    const [sheetHeaders, setSheetHeaders] = useState(null)
    const [recipientsEmails, setRecipientsEmails] = useState(null)

    const [inputErrors, setInputErrors] = useState({});
    const { Option } = Select
    const { register, handleSubmit, formState: { errors } } = useForm({})
    const { t, i18n } = useTranslation();
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const { user } = useContext(UserContext)
    const [showNotification, setShowNotification] = useState(false)
    const [changeDetected, setChangeDetected] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [errorNotification, setErrorNotification] = useState(false)
    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })

    const [exportDialogOpen, setExportDialogOpen] = useState(false)
    const [enqueueingExport, setEnqueueingExport] = useState(false)
    const [exportError, setExportError] = useState(null)
    const [exportNotificationOpen, setExportNotificationOpen] = useState(false)
    const [exportProgress, setExportProgress] = useState(0)
    const [exportFileUrl, setExportFileUrl] = useState(null)

    const onConfirmExport = async function () {
        window.analytics.track('Automatic Reports - Starts Exporting')
        setEnqueueingExport(true)
        setExportFileUrl(null)

        const { job_id } = await enqueueReportsExport({
            store_id: user.current_store.id,
            report_type: data?.report_type,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        watchExportProgress(job_id)

        setExportDialogOpen(false)
        setExportNotificationOpen(true)
        setEnqueueingExport(false)
    }

    const watchExportProgress = function (job_id) {
        const intervalId = setInterval(async () => {
            const { progress_percentage: progressPercentage, download_url: downloadUrl } = await getReportsExport(job_id)
            if (progressPercentage < 100) {
                setExportProgress(progressPercentage)
            } else {
                setExportFileUrl(downloadUrl)
                setExportProgress(0)
                clearInterval(intervalId)
            }
        }, 1000)
    }

    // Notification Dialog
    const handleExportDownload = function () {
        window.analytics.track('Automatic Reports - Downloads Exported')
        setExportNotificationOpen(false)
        window.open(process.env.REACT_APP_PLATFORM_URL + exportFileUrl)
    }

    const exportNotificationBody = (
        <>
            <div className="w-0 flex-1 flex justify-between">
                {exportFileUrl &&
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                }
                <div className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">
                    {!exportFileUrl ? <> {i18n.t('export_notification.generating')} </>
                        : <> {i18n.t('export_notification.generated')} </>}
                </div>
                {!exportFileUrl &&
                    <div className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{exportProgress}%</div>
                }
                {exportFileUrl &&
                    <button
                        type="button"
                        className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleExportDownload}
                    >
                        Descargar
                    </button>
                }
            </div>
        </>
    )

    const frequencyOptions = ['DAILY', 'WEEKLY', 'MONTHLY', 'FORTNIGHTLY', 'QUATERLY']

    const validEmail = (email, key) => {
        let valid = MAIL_REGEX.test(email)
        setInputErrors({ ...inputErrors, [key]: !valid })
        return valid;
    }

    const handleChangeEmails = (value, key) => {
        setChangeDetected(true)
        let values = []
        let string_values = value.toString()
        if (string_values != '') {
            values = string_values.split(",");
        }
        values = values.filter(email => validEmail(email, key))
        setRecipientsEmails(values)
    }

    const handleChangeFrequency = (value, key) => {
        if (data?.id && recipientsEmails.length > 0)
            setChangeDetected(true)
        setFrequency(value)
    }

    const handleClickCloseReportDetailSection = () => {
        let updated_path = (STORE_PATH + SETTINGS_AUTOMATIC_REPORTS).replace(":storeId", user.current_store.id)
        history.push(updated_path)
        onCancel()
    }

    const handleClickOnCancel = () => {
        setChangeDetected(false)
        setRecipientsEmails(data?.recipients_emails)
        setFrequency(data?.frequency)
    }

    const handleReportDeliveryStatus = () => {
        window.analytics.track('Automatic Reports - Starts Editing', { report_id: report_id, name: data?.report_type })
        updateStoreReport(report_id, {
            enabled: !data?.enabled,
            frequency: frequency,
            recipients_emails: recipientsEmails,
            sheet_headers: sheetHeaders
        }).then((response) => {
            setShowNotification(true)
            refetch()
        }).catch((e) => {
            setErrorNotification(true)
        })
    }

    const handleClickOnConfirm = () => {
        if (data?.id) {
            window.analytics.track('Automatic Reports - Starts Editing', { report_id: data?.id, name: data?.report_type })
            updateStoreReport(data?.id, {
                frequency: frequency,
                recipients_emails: recipientsEmails,
                sheet_headers: sheetHeaders
            }).then((response) => {
                setShowNotification(true)
                setChangeDetected(false)
                refetch()
            }).catch((e) => {
                setErrorNotification(true)
            })
        } else {
            window.analytics.track('Automatic Reports - Starts Creating', { name: data?.report_type })
            createStoreReport({
                store_id: user.current_store.id,
                report_type: data?.report_type,
                frequency: frequency,
                recipients_emails: recipientsEmails,
                enabled: true
            }).then((response) => {
                setShowNotification(true)
                setChangeDetected(false)
                setAutomaticReports((prevState) => ({
                    reportId: response?.id,
                    reportTemplate: null, // Only used when report is not set yet
                    tabContentAutomaticReports: '1'
                }))
                let updated_path = (storePath + SETTINGS_AUTOMATIC_REPORT_EDIT).replace(":report_id", response?.id)
                history.push(updated_path)
            }).catch((e) => {
                setErrorNotification(true)
            })
        }
    }

    const isLoadingData = () => (!isPreviousData && isLoading)

    if (isLoadingData() || isFetching)
        return <Loader show={true} />

    if (!isLoadingData() && isError)
        return <>Error: {error.message}</>

    return (
        <div className='w-full relative p-10'>
            <div className="w-full space-y-1 ">
                <div className="flex justify-start">
                    <ConfirmDialog
                        open={dialog.open}
                        setOpen={(value) => setDialog({ ...dialog, open: value })}
                        title={i18n.t("settings.validate_changes.title")}
                        description={i18n.t("settings.validate_changes.question")}
                        confirmLabel="Si"
                        onConfirm={handleClickOnConfirm}
                        loading={dialog.loading}
                    />
                    <div className="flex w-full flex-col">
                        <div className="flex flex-row">
                            <LeftCircleOutlined className="text-3xl mr-8 hover:text-indigo-700 mt-5" onClick={handleClickCloseReportDetailSection} />
                            <div>
                                <p className='text-xl mb-0 font-bold text-blue-gray-900 mt-7'> {i18n.t(`settings.automatic_reports.report_names.${data?.report_type}`)} </p>
                                <p className="mt-0 text-base text-gray-500"> {i18n.t("settings.automatic_reports.category")}: {i18n.t(`settings.automatic_reports.report_categories.${data?.category}`)} </p>
                            </div>
                            <div className='pl-3 py-3 px-3 mt-7 ml-auto'>
                                {report_id && (
                                    <button className="whitespace-nowrap border border-gray-300 shadow-sm
                        rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50 mr-4 disabled:opacity-60 disabled:cursor-not-allowed"
                                        onClick={() => handleReportDeliveryStatus()} disabled={!data?.enabled && !(recipientsEmails?.length > 0 && frequency?.length > 0)}
                                        >
                                        {data?.enabled ? (
                                            <>
                                                <StopIcon className="inline mb-0.5 mr-1 h-5 w-5" aria-hidden="true" />
                                                {i18n.t("settings.automatic_reports.report_details.report_buttons.pause_report")}
                                            </>
                                        ) : (
                                            <>
                                                <PlayIcon className="inline mb-0.5 mr-1 h-5 w-5" aria-hidden="true" />
                                                {i18n.t("settings.automatic_reports.report_details.report_buttons.restart_report")}
                                            </>
                                        )}
                                    </button>
                                )}
                                <button className="whitespace-nowrap border border-gray-300 shadow-sm
                        rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50"
                                    onClick={() => setExportDialogOpen(true)}>
                                    <DownloadIcon className="inline mb-0.5 mr-1 h-5 w-5" aria-hidden="true" />
                                    {i18n.t("settings.automatic_reports.report_details.report_buttons.download_report")}
                                </button>
                            </div>
                        </div>
                        <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                        <div className="flex flex-row">
                            <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'> {i18n.t("settings.automatic_reports.report_details.about_the_report_title")} </p>
                            <p className='text-md mb-0 text-gray-500 mt-7 ml-2 w-3/4'> {i18n.t(`settings.automatic_reports.report_details.report_descriptions.${data?.report_type}`)} </p>
                        </div>
                        <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                        <div className="flex flex-row">
                            <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'> {i18n.t("settings.automatic_reports.report_details.delivery_status")} </p>
                            <p className={`text-md mb-0 mt-7 ml-2 w-3/4 font-normal ${data?.enabled ? "text-green-500" : "text-gray-500"}`}> {i18n.t(`settings.automatic_reports.report_details.${data?.enabled}`)} </p>
                        </div>
                        <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                        <div className="flex flex-row">
                            <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'> {i18n.t("settings.automatic_reports.report_details.frequency_title")} </p>
                            <div className='text-md mb-0 text-gray-500 mt-7 ml-2 w-3/4'>
                                <Select
                                    mode="multiple"
                                    placeholder={i18n.t("settings.automatic_reports.report_details.frequency_placeholder")}
                                    className="w-full"
                                    value={frequency}
                                    onChange={(event) => handleChangeFrequency(event, "frequency")}
                                    filterOption={false}
                                    optionLabelProp="label"
                                >
                                    {frequencyOptions.map((option) => (
                                        <Option key={option} value={option} label={i18n.t(`settings.automatic_reports.report_frequency.${option}`)}>
                                            <span>
                                                {i18n.t(`settings.automatic_reports.report_frequency.${option}`)}
                                                <span className="text-gray-500 font-normal text-sm">, {i18n.t(`settings.automatic_reports.report_frequency.report_frequency_descriptions.${option}`)}</span>
                                            </span>
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                        <div className="flex flex-row mb-7">
                            <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'> {i18n.t("settings.automatic_reports.report_details.recipients_title")} </p>
                            <div className='text-md mb-0 text-gray-500 mt-7 ml-2 w-3/4'>
                                <Select
                                    mode="tags"
                                    placeholder={i18n.t("settings.automatic_reports.report_details.recipients_placeholder")}
                                    className="w-full"
                                    suffixIcon={null}
                                    tokenSeparators={[" "]}
                                    value={recipientsEmails}
                                    onChange={(event) => handleChangeEmails(event, "email")}
                                    open={false}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row mt-12">
                            <Button type="secondary" className="w-1/2 mr-2" onClick={handleClickOnCancel}>
                                {i18n.t("settings.automatic_reports.report_details.report_buttons.cancel")}
                            </Button>
                            <Button type="primary" className="w-1/2 ml-2" onClick={handleClickOnConfirm} disabled={!changeDetected}>
                                {i18n.t("settings.automatic_reports.report_details.report_buttons.save")}
                            </Button>
                        </div>
                        <ConfirmDialog
                            open={exportDialogOpen}
                            setOpen={value => setExportDialogOpen(value)}
                            title={`${i18n.t("settings.automatic_reports.export_report")}: ${i18n.t(`settings.automatic_reports.report_names.${data?.report_type}`)}`}
                            description=""
                            confirmLabel={i18n.t("replenishments.export_dialog.confirm")}
                            cancelLabel={i18n.t("replenishments.export_dialog.cancel")}
                            errorMessage={exportError}
                            onConfirm={onConfirmExport}
                            loading={enqueueingExport}
                        />
                        <Notification
                            show={exportNotificationOpen}
                            setShow={setExportNotificationOpen}
                            customBody={exportNotificationBody}
                            ephemeral={false}
                        />
                        <Notification show={showNotification}
                            setShow={setShowNotification}
                            type="success"
                            title={i18n.t("settings.automatic_reports.report_details.report_notifications.done")} />
                        <Notification
                            show={errorNotification}
                            setShow={setErrorNotification}
                            type="error"
                            title={i18n.t("settings.automatic_reports.report_details.report_notifications.error")} />
                    </div>
                </div>
            </div>
        </div>
    )

}