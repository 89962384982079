
import {useContext, useRef, useState} from "react";
import { Loader } from '../../components/Loader';
import {FormattedDate, BasicTable} from "../../components";
import {useQuery} from "react-query";
import {fetchProductLots} from "../../services";
import {useParams, useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {UserContext} from "../../hooks/UserContext";
import {
    setProductsListPageSize
} from '../../redux/pageSizeSlice';
import { InformationCircleIcon } from '@heroicons/react/solid'

export const ListLotsContainer = () => {

    const { t, i18n} = useTranslation();
    let { id } = useParams()
    const tableRef = useRef()
    const dispatch = useDispatch()
    
    const pageSize = useSelector((state) => state.pageSize.productsList)
    const {user} = useContext(UserContext)
    const [searchParams, setSearchParams] = useState({
        page: 1,
        per_page: 100, //Force it to 100 in this case, intentionally
        store_id: user.current_store?.id,
        include_stock: true
    })

    const {
        isLoading,
        isFetching,
        isError,
        error,
        data,
        refetch
    } = useQuery(['replenishment-products', id, searchParams], ()=>fetchProductLots(id, searchParams))

const columns = [
    {
        // Build our expander column
        id: 'id', // Make sure it has an ID producto
        accessor: 'lot',
        Header: "LOTE",
        disableFilters: false,
        disableSortBy: false,
        disableWrap: true,
    },
    {
        Header: "inventory.listProducts.stock",
        accessor: 'stock.IN_WAREHOUSE', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.for_selling",
        accessor: 'stock.AVAILABLE', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.orders",
        accessor: 'stock.RESERVED', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.damaged",
        accessor: 'stock.DAMAGED', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.holded",
        accessor: 'stock.HOLDED', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.expired",
        accessor: 'stock.EXPIRED', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.shipped",
        accessor: 'stock.SHIPPED', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "inventory.listProducts.status",
        accessor: 'status',
        disableFilters: false,
        disableSortBy: false,
    },
]

    const getLot = (lot) => {
        return <>{lot.unique_code}<br/>{<i><FormattedDate date={lot.expiration_date} onlyDate/></i>}</>
    }

    const getStatus = (lot) => {
        return <>{i18n.t(`inventory.listProducts.${lot.status}`)}</>
    }


    return (
        <>
            {/* <h3>Tu recibo fué completado</h3> */}
            {isLoading ? (
                    <>{i18n.t("lots.view_lots_detailed")}</>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (
                    <>

                    <Loader show={isFetching} />
                   
                    {
                        data.lots.length > 0 ?
                        <>
                        {i18n.t("lots.view_lots_detailed")}
                        <div className= "mt-5">
                            <BasicTable
                                labelToPaginator="Lotes"
                                columns={columns}
                                showHeader
                                showPaginator
                                data={data.lots.map(lot => {
                                    return {
                                        ...lot,
                                        lot: getLot(lot),
                                        status: getStatus(lot)
                                    }
                                })}
                                paginationMeta={data?.meta}
                                onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                                onPageSizeChange= { (pageSize) => {
                                    setSearchParams({...searchParams, per_page: pageSize, page: 1})
                                    dispatch(setProductsListPageSize(pageSize))
                                    tableRef.current.resetPagination()
                                }}
                                pagesSize = {searchParams.per_page}
                            />
                        </div>

                        <div className="rounded-md bg-blue-50 p-4 mt-5">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">{i18n.t("lots.lot_support_actions")}</p>
                                </div>
                            </div>
                        </div>
                        </>
                        :
                        <>
                            <div className="rounded-md bg-blue-50 p-4 mt-5">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">{i18n.t("lots.no_lots")}</p>
                                </div>
                            </div>
                            </div>

                        </>
                    }

                    </>
                    )}
                    
        </>
    )
}