import React, {useContext, useEffect} from "react";
import {UserContext} from "../hooks/UserContext";
import LogRocket from 'logrocket';
import { ZendeskWidget } from "./Zendesk/ZendeskWidget";
import { isProductionEnvironment } from "../helpers/environmentHelper";
import { init } from '@amplitude/analytics-browser';
import {AsyncTaskNotification} from "./AsyncTaskNotification";
import {useSelector} from "react-redux";
import {asyncTasksIds} from "../redux/asyncTaskSlice";

export function Layout1({menu, children}) {
    const {user} = useContext(UserContext)
    const asyncTasks = useSelector(asyncTasksIds);

    useEffect(() => {     
        console.log(user)
        if(isProductionEnvironment && user?.isLogged && user?.accounts ){
            LogRocket.identify(user.id, {
                name: user.first_name+" "+user.last_name,
                email: user.email
            });
            
            init("5fecf7ab210d5633c91637d4c0b15494", user.email);

            window.analytics.identify(user.id, {
                name: user.first_name+" "+user.last_name,
                email: user.email,
                account: user.accounts[0]?.name
            });

            window.analytics.group(user.accounts[0]?.id, {
                name: user.accounts[0]?.name
            });
        }

    }, [user])
   
    return (
        <>
            <div className="flex flex-col h-screen justify-between">
                <div className=" bg-red-500">{menu}</div>
                <div className="h-5/6 flex flex-col justify-between flex-grow bg-gray-100">
                    {children}
                </div>
            </div>
            {user?.current_store?.warehouses[0]?.country && <ZendeskWidget country={user.current_store.warehouses[0].country}/>}
            {user && asyncTasks && asyncTasks.map(asyncTaskId =>
                <AsyncTaskNotification jobId={asyncTaskId} key={asyncTaskId} />
            )}
        </>
    )
}