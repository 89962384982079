import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { PlusIcon, MinusIcon } from "@heroicons/react/outline"

import { formatMoney, numberToString } from "../../../helpers/internationalization"
import { BILLING_SERVICES } from "../constants"
import { Button } from "../../../components"
import { DownloadIcon } from "@heroicons/react/solid"

const StoreBillingServicesSummary = ({storeBillingServices, storeShippingPackages, warehouseVolumeCategories, currentStorageServiceUsages, onExport = () => {}, exportBusy = false}) => {
    const { i18n } = useTranslation()
    const [showSections, setShowSections] = useState({
        PICK_AND_PACK: false,
        PICK_AND_PACK_PACKAGE: false,
        REPLENISHMENT: false,
        RETURN: false,
        SHIPPING_LABEL: false,
        INTERNATIONAL_SHIPPING_LABEL: false,
        STORAGE_BY_LOCATION: false,
        STORAGE_BY_PRODUCT: false,
        OTHERS: false,
        CREDIT: false
    })

    const TABLE_HEADERS = {
        TITLE: "TITLE",
        UNIT: "UNIT",
        UNIT_VALUE: "UNIT_VALUE",
        QUANTITY: "QUANTITY",
        TOTAL: "TOTAL"
    }

    const BILLING_SERVICE_TYPE_CATEGORIES = {
        CUSTOM: [null],
        CUSTOM_RECURRING: [null],
        STORAGE_BY_LOCATION: ["NONE_APPLIES"],
        STORAGE_BY_PRODUCT: ["NONE_APPLIES"],
        SHIPPING_LABEL: ["SP_CAP", "SP_INT", "RJ_CAP", "RJ_INT", "ES_CAP", "ES_INT", "MG_CAP", "MG_INT", "BA_CAP", "BA_INT", "SE_CAP", "SE_INT", "PE_CAP", "PE_INT", "AL_CAP", "AL_INT", "PB_CAP", "PB_INT", "RN_CAP", "RN_INT", "CE_CAP", "CE_INT", "PI_CAP", "PI_INT", "MA_CAP", "MA_INT", "PA_CAP", "PA_INT", "AP_CAP", "AP_INT", "AM_CAP", "AM_INT", "RR_CAP", "RR_INT", "AM_INT_2", "AC_CAP", "AC_INT", "DF_CAP", "DF_INT", "GO_CAP", "DF_INT_2", "GO_CAP_2", "GO_INT", "RO_CAP", "RO_INT", "TO_CAP", "TO_INT", "MT_CAP", "MT_INT", "RO_INT_2", "RO_INT_3", "MS_CAP", "MS_INT", "PR_CAP", "PR_INT", "SC_CAP", "SC_INT", "RS_CAP", "RS_INT", "SUPER_SAVER", "LOCAL", "PREMIUM", "ECONOMY"],
        SHIPPING_LABEL_EXTENDED_ZONE: ["SP_CAP", "SP_INT", "RJ_CAP", "RJ_INT", "ES_CAP", "ES_INT", "MG_CAP", "MG_INT", "BA_CAP", "BA_INT", "SE_CAP", "SE_INT", "PE_CAP", "PE_INT", "AL_CAP", "AL_INT", "PB_CAP", "PB_INT", "RN_CAP", "RN_INT", "CE_CAP", "CE_INT", "PI_CAP", "PI_INT", "MA_CAP", "MA_INT", "PA_CAP", "PA_INT", "AP_CAP", "AP_INT", "AM_CAP", "AM_INT", "RR_CAP", "RR_INT", "AM_INT_2", "AC_CAP", "AC_INT", "DF_CAP", "DF_INT", "GO_CAP", "DF_INT_2", "GO_CAP_2", "GO_INT", "RO_CAP", "RO_INT", "TO_CAP", "TO_INT", "MT_CAP", "MT_INT", "RO_INT_2", "RO_INT_3", "MS_CAP", "MS_INT", "PR_CAP", "PR_INT", "SC_CAP", "SC_INT", "RS_CAP", "RS_INT", "SUPER_SAVER", "LOCAL", "PREMIUM", "ECONOMY"],
        INTERNATIONAL_SHIPPING_LABEL: ["NORTH_AMERICA","LATIN_AMERICA","CARIBBEAN","EUROPE","ASIA","AFRICA"],
        SHIPPING_LABEL_PICKUP: ["NONE_APPLIES"],
        RETURN_ORDER: ["NONE_APPLIES"],
        RETURN_ORDER_LOTS: ["NONE_APPLIES"],
        RETURN_SHIPPING_LABEL: ["NONE_APPLIES"],
        PICK_AND_PACK: ["NONE_APPLIES"],
        PICK_AND_PACK_INSERT: ["NONE_APPLIES"],
        PICK_AND_PACK_SERIAL_NUMBER: ["NONE_APPLIES"],
        PICK_AND_PACK_FRAGILE: ["NONE_APPLIES"],
        PICK_AND_PACK_PACKAGE: ["parcel_bag", "jiffy_envelope", "cubbo_box", "packageless", "customer_box"],
        REPLENISHMENT: ["NONE_APPLIES"],
        REPLENISHMENT_LOTS: ["NONE_APPLIES"],
        CLAIM: ["NONE_APPLIES"],
        WORK_ORDER: ["NONE_APPLIES"],
        UNIDENTIFIED_RETURN: ["NONE_APPLIES"],
        SHIPMENTS_RETURN_BY_CARRIER: ["NONE_APPLIES"],
        DIFAL: ["NONE_APPLIES"],
        SHIPPING_INSURANCE: ["NONE_APPLIES"],
        PACKING_MATERIAL: ["CORN_EXTRUDATE", "BUBBLE_WRAP", "KRAFT_PAPER"]
    }

    const GROUPED_SERVICE_TYPES = {
        PICK_AND_PACK: [BILLING_SERVICES.PICK_AND_PACK, BILLING_SERVICES.PICK_AND_PACK_INSERT, BILLING_SERVICES.PICK_AND_PACK_SERIAL_NUMBER, BILLING_SERVICES.PICK_AND_PACK_FRAGILE],
        PICK_AND_PACK_PACKAGE: [BILLING_SERVICES.PICK_AND_PACK_PACKAGE, BILLING_SERVICES.PACKING_MATERIAL],
        REPLENISHMENT: [BILLING_SERVICES.REPLENISHMENT, BILLING_SERVICES.REPLENISHMENT_LOTS],
        RETURN: [BILLING_SERVICES.RETURN_ORDER, BILLING_SERVICES.RETURN_ORDER_LOTS, BILLING_SERVICES.RETURN_SHIPPING_LABEL, BILLING_SERVICES.UNIDENTIFIED_RETURN, BILLING_SERVICES.SHIPPING_LABEL, BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE, BILLING_SERVICES.SHIPPING_LABEL_PICKUP,BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL],
        SHIPPING_LABEL: [BILLING_SERVICES.SHIPPING_LABEL, BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE, BILLING_SERVICES.SHIPPING_LABEL_PICKUP],
        INTERNATIONAL_SHIPPING_LABEL: [BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL],
        STORAGE_BY_LOCATION: [BILLING_SERVICES.STORAGE_BY_LOCATION],
        STORAGE_BY_PRODUCT: [BILLING_SERVICES.STORAGE_BY_PRODUCT],
        DIFAL: [BILLING_SERVICES.DIFAL],
        SHIPPING_INSURANCE: [BILLING_SERVICES.SHIPPING_INSURANCE],
        OTHERS: [BILLING_SERVICES.CUSTOM, BILLING_SERVICES.WORK_ORDER],
        CREDIT: null
    }
    
    const SHIPPING_LABEL_SERVICE_TYPES = [
        BILLING_SERVICES.SHIPPING_LABEL, 
        BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE, 
        BILLING_SERVICES.SHIPPING_LABEL_PICKUP,
        BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL,
    ]
    const GROUPED_SERVICE_KEYS = {
        PICK_AND_PACK: "PICK_AND_PACK",
        PICK_AND_PACK_PACKAGE: "PICK_AND_PACK_PACKAGE",
        REPLENISHMENT: "REPLENISHMENT",
        RETURN: "RETURN",
        SHIPPING_LABEL: "SHIPPING_LABEL",
        INTERNATIONAL_SHIPPING_LABEL: "INTERNATIONAL_SHIPPING_LABEL",
        STORAGE_BY_LOCATION: "STORAGE_BY_LOCATION",
        STORAGE_BY_PRODUCT: "STORAGE_BY_PRODUCT",
        DIFAL: "DIFAL",
        SHIPPING_INSURANCE: "SHIPPING_INSURANCE",
        PACKING_MATERIAL: "PACKING_MATERIAL",
        OTHERS: "OTHERS",
        CREDIT: "CREDIT"
    }

    const RANGE_EXCLUSION_MODES = {
        EXCLUDE_FROM: "EXCLUDE_FROM",
        EXCLUDE_TO: "EXCLUDE_TO"
    }

    const generatePriceRangeTitle = (priceRange, billingService, priceRangeTitle) => {
        if(Array.isArray(priceRangeTitle)){
            return priceRangeTitle.join(", ")
        } else if(typeof priceRangeTitle === "string"){
            return priceRangeTitle
        }

        const priceRangeTo = isNaN(parseFloat(priceRange.to)) ? priceRange.to : parseFloat(priceRange.to)
        const priceRangeFrom = isNaN(parseFloat(priceRange.from)) ? priceRange.from : parseFloat(priceRange.from)

        let rangeTranslationKey = "billing.services_summary_table.usage_ranges_details"
        const singularOrPluralTranslationKey = ((priceRangeTo !== null && priceRangeTo !== 1) || (priceRangeFrom !== 1 && priceRangeTo === null)) ? "plural" : "singular"
        const fromToTranslationKey = priceRangeTo === null ? "from" : "from_to"

        switch(billingService.service_type){
            case BILLING_SERVICES.PICK_AND_PACK:
            case BILLING_SERVICES.PICK_AND_PACK_SERIAL_NUMBER:
                rangeTranslationKey = `${rangeTranslationKey}.general.ORDERS_PIECES.${singularOrPluralTranslationKey}_${fromToTranslationKey}`
                break

            case BILLING_SERVICES.REPLENISHMENT:
            case BILLING_SERVICES.REPLENISHMENT_LOTS:
            case BILLING_SERVICES.RETURN_ORDER:
            case BILLING_SERVICES.RETURN_ORDER_LOTS:
                rangeTranslationKey = `${rangeTranslationKey}.general.PIECES.${singularOrPluralTranslationKey}_${fromToTranslationKey}`
                break

            case BILLING_SERVICES.PICK_AND_PACK_INSERT:
                rangeTranslationKey = `${rangeTranslationKey}.general.ORDERS_INSERTS.${singularOrPluralTranslationKey}_${fromToTranslationKey}`
                break

            case BILLING_SERVICES.PICK_AND_PACK_PACKAGE:
            case BILLING_SERVICES.RETURN_SHIPPING_LABEL:
            case BILLING_SERVICES.SHIPPING_LABEL:
                rangeTranslationKey = `${rangeTranslationKey}.general.WEIGHT.${singularOrPluralTranslationKey}_${fromToTranslationKey}`
                break

            default:
                if(billingService.service_type_category !== "NONE_APPLIES" && billingService.service_type_category !== null){
                    rangeTranslationKey = `${rangeTranslationKey}.${billingService.service_type}.${billingService.service_type_category}`
                } else{
                    rangeTranslationKey = `${rangeTranslationKey}.${billingService.service_type}`
                }
        }

        let rangeDetailsTranslation = i18n.t(rangeTranslationKey)
        const firstLetterCapitalized = rangeDetailsTranslation[0].toUpperCase()
        rangeDetailsTranslation = firstLetterCapitalized + rangeDetailsTranslation.slice(1)

        rangeDetailsTranslation = rangeDetailsTranslation.replace("{FROM}", priceRangeFrom)

        if(priceRangeTo){
            rangeDetailsTranslation = rangeDetailsTranslation.replace("{TO}", priceRangeTo)
        }

        return rangeDetailsTranslation
    }

    const calculateUsageQuantity = (usageSummary, serviceType, groupedServicesKey = null) => {
        let priceRangeQuantity = null

        if(!usageSummary){
            priceRangeQuantity = 0
        } else if(serviceType === BILLING_SERVICES.CUSTOM || serviceType === BILLING_SERVICES.CUSTOM_RECURRING){
            priceRangeQuantity = 1
        } else if(serviceType === BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL || serviceType === BILLING_SERVICES.CLAIM || (usageSummary.is_excess && (serviceType === BILLING_SERVICES.SHIPPING_LABEL || serviceType === BILLING_SERVICES.RETURN_SHIPPING_LABEL))){
            priceRangeQuantity = usageSummary.count_billed_usage
        } else if(serviceType === BILLING_SERVICES.STORAGE_BY_LOCATION){
            const billedUsage = parseFloat(usageSummary.billed_usage)
            const categoryRelativeVolume = parseFloat(usageSummary.billed_details.category_relative_volume)

            priceRangeQuantity = billedUsage === 0 && categoryRelativeVolume === 0 ? 0 : (billedUsage / categoryRelativeVolume)
        } else if(serviceType === BILLING_SERVICES.STORAGE_BY_PRODUCT){
            const billedUsage = parseFloat(usageSummary.billed_usage)

            priceRangeQuantity = billedUsage
        } else {
            priceRangeQuantity = usageSummary.price_mode === "PER_UNIT" ? usageSummary.sum_billed_usage : usageSummary.count_billed_usage
        }

        if (SHIPPING_LABEL_SERVICE_TYPES.includes(serviceType) && usageSummary && groupedServicesKey === GROUPED_SERVICE_KEYS.RETURN){
            priceRangeQuantity = usageSummary.count_billed_usage_return_by_carrier
        }

        priceRangeQuantity = parseFloat(priceRangeQuantity)

        if(isNaN(priceRangeQuantity)){
            throw new Error("Invalid usage quantity")
        }

        return priceRangeQuantity
    }

    const calculateUsageTotal = (usageSummary, serviceType, groupedServicesKey = null ) => {
        let priceRangeTotal = null

        if(!usageSummary){
            priceRangeTotal = 0
        } else if(serviceType === BILLING_SERVICES.CUSTOM || serviceType === BILLING_SERVICES.CUSTOM_RECURRING || serviceType === BILLING_SERVICES.STORAGE_BY_LOCATION || serviceType === BILLING_SERVICES.STORAGE_BY_PRODUCT){
            priceRangeTotal = parseFloat(usageSummary.billed_total_price)
        } else{
            priceRangeTotal = parseFloat(usageSummary.sum_billed_price)
        }

        if (SHIPPING_LABEL_SERVICE_TYPES.includes(serviceType) && usageSummary && groupedServicesKey === GROUPED_SERVICE_KEYS.RETURN){
            priceRangeTotal = parseFloat(usageSummary.sum_billed_price_return_by_carrier)
        }

        if(isNaN(priceRangeTotal)){
            throw new Error("Invalid usage total")
        }

        return priceRangeTotal
    }

    const calculateUsageUnitPrice = (usageSummary, serviceType, usageTotal, usageQuantity, priceRange) => {
        let unitPrice = null
        let isUnitPriceAvailable = true

        if(!usageSummary && (serviceType === BILLING_SERVICES.CLAIM || serviceType === BILLING_SERVICES.STORAGE_BY_LOCATION || serviceType === BILLING_SERVICES.STORAGE_BY_PRODUCT || serviceType === BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL || priceRange?.excess)){
            unitPrice = "NA"
            isUnitPriceAvailable = false
        } else if(usageSummary?.is_credit){
             unitPrice = usageTotal === 0 && usageQuantity === 0 ? 0 : (usageTotal / usageQuantity)
        } else if(!!usageSummary && (serviceType === BILLING_SERVICES.STORAGE_BY_LOCATION || serviceType === BILLING_SERVICES.STORAGE_BY_PRODUCT)){
            unitPrice = usageTotal === 0 && usageQuantity === 0 ? 0 : (usageTotal / usageQuantity)
        } else if(!!usageSummary && (serviceType === BILLING_SERVICES.CLAIM || serviceType === BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL || usageSummary.is_excess || serviceType === BILLING_SERVICES.UNIDENTIFIED_RETURN)){
            unitPrice = usageTotal === 0 && usageQuantity === 0 ? 0 : (usageTotal / usageQuantity)
        } else if(serviceType === BILLING_SERVICES.CUSTOM || serviceType === BILLING_SERVICES.CUSTOM_RECURRING || serviceType === BILLING_SERVICES.STORAGE_BY_PRODUCT){
            unitPrice = usageTotal
        } else if(!usageSummary){
            unitPrice = parseFloat(priceRange.price)
        } else {
            unitPrice = parseFloat(usageSummary.range_price)
        }

        if(isUnitPriceAvailable && isNaN(unitPrice)){
            throw new Error("Invalid unit price")
        }

        return unitPrice
    }

    const generatePriceRangeUnit = (priceRange, serviceType, useCreditData) => {
        let translationKey = null

        if(useCreditData && serviceType === BILLING_SERVICES.CUSTOM){
            translationKey = `billing.services_summary_table.units.${serviceType}.${priceRange.price_mode}_DISCOUNT`
        } else if(!useCreditData && serviceType === BILLING_SERVICES.CUSTOM){
            translationKey = `billing.services_summary_table.units.${serviceType}.${priceRange.price_mode}_CHARGE`
        } else if(useCreditData && serviceType !== BILLING_SERVICES.CLAIM){
            translationKey = "billing.services_summary_table.units.REFUND"
        } else {
            translationKey = `billing.services_summary_table.units.${serviceType}.${priceRange.price_mode}`
        }

        if(priceRange.excess) {
            translationKey = `${translationKey}_EXCESS`
        }
        return i18n.exists(translationKey) ? i18n.t(translationKey) : ""
    }

    const generateReturnByCarrierServiceData = (groupedUsages) => {
        let usagesSumQuantity = 0
        let usagesSumTotal = 0

        groupedUsages.forEach(objeto => {
             usagesSumQuantity += objeto.quantity;
             usagesSumTotal += objeto.total;
         });
         // create a new serverice type for return by carrier for the groupedUsages of shipping labels  of retrun by carrier and unidentified return
        return {
            serviceType: BILLING_SERVICES.SHIPMENTS_RETURN_BY_CARRIER,
            serviceTitle: i18n.t(`billing.services_summary_table.tables_sections.${BILLING_SERVICES.SHIPMENTS_RETURN_BY_CARRIER}`),
            groupedUsages: groupedUsages,
            usagesSumQuantity: usagesSumQuantity,
            usagesSumTotal: usagesSumTotal,
            isCredit: false,
            isCategorized: true,
            hasSamePriceMode: true
        }
    }

    const generateStorageServiceData = (billingService, volumeCategories, useCreditData, storageServiceUsages) => {
        if(!billingService && !storageServiceUsages){
            throw new Error("Storage service usage data must exists")
        }

        let usagesSumQuantity = 0
        let usagesSumTotal = 0
        const selectedBillingService = !useCreditData && storageServiceUsages ? storageServiceUsages : billingService

        const groupedUsages = volumeCategories.map(volumeCategory => {
            if(selectedBillingService.price_configuration.length > 1){
                throw new Error("Invalid price configuration for storage service")
            }

            let usageSummary = null

            if(!useCreditData && storageServiceUsages){
                const storageUsage = storageServiceUsages.custom_service_usages.find(({billed_details}) => (
                    billed_details.volume_category_id === volumeCategory.id
                ))
                usageSummary = {
                    range_price: selectedBillingService.price_configuration[0].price,
                    price_mode: selectedBillingService.price_configuration[0].price_mode,
                    is_excess: selectedBillingService.price_configuration[0].is_excess,
                    to: selectedBillingService.price_configuration[0].to,
                    from: selectedBillingService.price_configuration[0].from,
                    is_credit: useCreditData,
                    billed_total_price: storageUsage.billed_total_price,
                    billed_usage: storageUsage.billed_usage,
                    billed_details: {
                        volume_category_id: storageUsage.billed_details.volume_category_id,
                        volume_category_code: storageUsage.billed_details.volume_category_code,
                        category_relative_volume: storageUsage.billed_details.category_relative_volume,
                        date_time_range: storageUsage.billed_details.date_time_range
                    },
                    id: null
                }
            } else{
                usageSummary = billingService.usage_summary.find(({billed_details, is_credit}) => (
                    billed_details.volume_category_id === volumeCategory.id && is_credit === useCreditData
                ))
            }

            const quantity = calculateUsageQuantity(usageSummary, selectedBillingService.service_type)
            const priceRangeTotal = calculateUsageTotal(usageSummary, selectedBillingService.service_type)
            const unitPrice = calculateUsageUnitPrice(usageSummary, selectedBillingService.service_type, priceRangeTotal, quantity, selectedBillingService.price_configuration[0])

            usagesSumQuantity += quantity
            usagesSumTotal += priceRangeTotal

            const titleTranslationKey = `billing.services_summary_table.usage_ranges_details.${selectedBillingService.service_type}.${volumeCategory.category_code}`

            return {
                title: i18n.exists(titleTranslationKey) ? i18n.t(titleTranslationKey) : "",
                unit: i18n.t(`billing.services_summary_table.units.${selectedBillingService.service_type}.${selectedBillingService.price_configuration[0].price_mode}`),
                unit_value: unitPrice,
                quantity: quantity,
                total: priceRangeTotal
            }
        }).filter(({total}) => total !== 0)

        return {
            serviceType: selectedBillingService.service_type,
            serviceTitle: i18n.t(`billing.services_summary_table.tables_sections.${selectedBillingService.service_type}`),
            groupedUsages: groupedUsages,
            usagesSumQuantity: usagesSumQuantity,
            usagesSumTotal: usagesSumTotal,
            isCredit: useCreditData,
            isCategorized: false
        }
    }

    const generateInternationalShippingLabelServiceData = (groupedServicesKey,billingService, useCreditData) => {
        let usagesSumQuantity = 0
        let usagesSumTotal = 0

        const groupedUsages = billingService.usage_summary.filter(({is_credit}) => is_credit === useCreditData).map(usageSummary => {

            const quantity = calculateUsageQuantity(usageSummary, billingService.service_type, groupedServicesKey)
            const priceRangeTotal = calculateUsageTotal(usageSummary, billingService.service_type, groupedServicesKey)
            const unitPrice = calculateUsageUnitPrice(usageSummary, billingService.service_type, priceRangeTotal, quantity, null)

            usagesSumQuantity += quantity
            usagesSumTotal += priceRangeTotal

            return {
                title: i18n.t(`billing.services_summary_table.tables_sections.${billingService.service_type}.${billingService.service_type_category}`),
                unit: i18n.t(`billing.services_summary_table.units.${billingService.service_type}`),
                unit_value: unitPrice,
                quantity: quantity,
                total: priceRangeTotal
            }
        }).filter(({total}) => total !== 0)

        return {
            serviceType: billingService.service_type,
            serviceTitle: i18n.t(`billing.services_summary_table.tables_sections.${billingService.service_type}`),
            groupedUsages: groupedUsages,
            usagesSumQuantity: usagesSumQuantity,
            usagesSumTotal: usagesSumTotal,
            isCredit: useCreditData,
            isCategorized: false
        }
    }

    const generateServiceDataByPriceConfiguration = (groupedServicesKey, billingService, shippingPackages, useCreditData, hideNotBilledNorDiscounted) => {
        let usagesSumQuantity = 0
        let usagesSumTotal = 0

        const notCategorizedServices = [
            BILLING_SERVICES.CUSTOM,
            BILLING_SERVICES.CLAIM,
            BILLING_SERVICES.SHIPPING_LABEL_PICKUP,
            BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE,
            BILLING_SERVICES.PICK_AND_PACK_FRAGILE,
            BILLING_SERVICES.WORK_ORDER,
            BILLING_SERVICES.UNIDENTIFIED_RETURN
        ]

        const isCategorized = notCategorizedServices.some(notCategorizedService => notCategorizedService === billingService.service_type) ? false : true

        let groupedUsages = billingService.price_configuration.map(priceRange => {
            const priceRangeFrom = isNaN(parseFloat(priceRange.from)) ? priceRange.from : parseFloat(priceRange.from)
            const priceRangeTo = isNaN(parseFloat(priceRange.to)) ? priceRange.to : parseFloat(priceRange.to)

            let priceRangeTitle = null
            let priceRangeDescription = null

            switch(billingService.service_type){
                case BILLING_SERVICES.PICK_AND_PACK_PACKAGE:
                    {
                        priceRangeTitle = shippingPackages.filter(({package_type, volumetric_weight, warehouse_id}) => {
                            const volumetricWeight = parseFloat(volumetric_weight)
                            const isFromValid = priceRange.range_exclusion === RANGE_EXCLUSION_MODES.EXCLUDE_FROM ? (volumetricWeight > priceRangeFrom) : (volumetricWeight >= priceRangeFrom)
                            const isToValid = priceRangeTo === null ? true : priceRange.range_exclusion === RANGE_EXCLUSION_MODES.EXCLUDE_TO ? (volumetricWeight < priceRangeTo) : (volumetricWeight <= priceRangeTo)
                            const isWarehouseValid = billingService.warehouse_id === warehouse_id ? true : false

                            return package_type === billingService.service_type_category && isFromValid && isToValid && isWarehouseValid ? true : false
                        })?.map(({volumetric_weight}) => i18n.t("billing.services_summary_table.volumetric_weight_unit").replace("{VOLUMETRIC_WEIGHT}", volumetric_weight))

                        if(!priceRangeTitle || priceRangeTitle.length === 0){
                            priceRangeTitle = i18n.t(`billing.services_summary_table.table_${groupedServicesKey}.no_packages`)
                        }
                        break
                    }
                case BILLING_SERVICES.CUSTOM:
                    priceRangeTitle = billingService.custom_service_details.title
                    priceRangeDescription = billingService.custom_service_details.description
                    break

                default:
                    break
            }

            const foundUsageSummary = billingService.usage_summary.find(priceRangeUsageSummary => {
                const summaryFrom = isNaN(parseFloat(priceRangeUsageSummary.from)) ? priceRangeUsageSummary.from : parseFloat(priceRangeUsageSummary.from)
                const summaryTo = isNaN(parseFloat(priceRangeUsageSummary.to)) ? priceRangeUsageSummary.to : parseFloat(priceRangeUsageSummary.to)

                return priceRangeUsageSummary.is_credit === useCreditData && summaryFrom === priceRangeFrom && summaryTo === priceRangeTo ? true : false
            })

            const priceRangeQuantity = calculateUsageQuantity(foundUsageSummary, billingService.service_type, groupedServicesKey)
            const priceRangeTotal = calculateUsageTotal(foundUsageSummary, billingService.service_type, groupedServicesKey)
            const unitPrice = calculateUsageUnitPrice(foundUsageSummary, billingService.service_type, priceRangeTotal, priceRangeQuantity, priceRange)

            usagesSumQuantity += priceRangeQuantity
            usagesSumTotal += priceRangeTotal

            return {
                title: generatePriceRangeTitle(priceRange, billingService, priceRangeTitle),
                category: billingService.service_type_category,
                description: priceRangeDescription,
                unit: generatePriceRangeUnit(priceRange, billingService.service_type, useCreditData),
                unit_value: unitPrice,
                quantity: priceRangeQuantity,
                total: priceRangeTotal
            }
        }).filter(({quantity}) => quantity !== 0)

        if(hideNotBilledNorDiscounted || billingService.service_type === "PICK_AND_PACK_PACKAGE"){
            groupedUsages = groupedUsages.filter(({total}) => total !== 0)
        }

        let titleTranslationKey = `billing.services_summary_table.tables_sections.${billingService.service_type}`

        if(billingService.service_type_category && billingService.service_type_category !== "NONE_APPLIES"){
            titleTranslationKey = `${titleTranslationKey}.${billingService.service_type_category}`
        }

        const hasSamePriceMode = groupedUsages.every((groupedServiceUsage, currentGroupedServiceUsageIndex, allGroupedUsages) => (
            allGroupedUsages[allGroupedUsages.length - 1].unit === groupedServiceUsage.unit
        ))

        return {
            serviceType: billingService.service_type,
            serviceTitle: i18n.exists(titleTranslationKey) ? i18n.t(titleTranslationKey) : "",
            groupedUsages: groupedUsages,
            usagesSumQuantity: usagesSumQuantity,
            usagesSumTotal: usagesSumTotal,
            isCredit: useCreditData,
            isCategorized: isCategorized,
            hasSamePriceMode: hasSamePriceMode
        }
    }

    const generateGroupedServicesTableData = (groupedBillingServices, groupedServicesKey, shippingPackages, volumeCategories, useCreditData, storageServiceUsages) => {
        let servicesTotal = 0
        let groupedServicesData = null
        const hideNotBilledNorDiscounted = true

        if(groupedServicesKey === (GROUPED_SERVICE_KEYS.STORAGE_BY_LOCATION || GROUPED_SERVICE_KEYS.STORAGE_BY_PRODUCT) && !useCreditData && storageServiceUsages){
            const groupedUsagesData = generateStorageServiceData(null, volumeCategories, useCreditData, storageServiceUsages)
            servicesTotal += groupedUsagesData.usagesSumTotal
            groupedServicesData = [groupedUsagesData]
        } 
        else if (groupedServicesKey === GROUPED_SERVICE_KEYS.RETURN){
            let groupedUsagesDataReturnByCarrier = null
            let groupedServicesShippingLabels = groupedBillingServices.filter(({service_type}) => SHIPPING_LABEL_SERVICE_TYPES.includes(service_type))

            groupedServicesData = groupedBillingServices.map(billingService => {
                let groupedUsagesData = null
                if (billingService.service_type ==  BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL){
                    groupedUsagesData = generateInternationalShippingLabelServiceData(groupedServicesKey,billingService, useCreditData)
                }else{
                    groupedUsagesData = generateServiceDataByPriceConfiguration(groupedServicesKey, billingService, shippingPackages, useCreditData, hideNotBilledNorDiscounted)
                }
                servicesTotal += groupedUsagesData.usagesSumTotal
                return groupedUsagesData
            })          

            if (groupedServicesShippingLabels){
              
                const groupedUsagesArray = groupedServicesData.flatMap((service) => { 
                    if (SHIPPING_LABEL_SERVICE_TYPES.includes(service.serviceType)) {
                        return service.groupedUsages
                    } else {
                        return []
                    }
                });
                groupedUsagesDataReturnByCarrier = generateReturnByCarrierServiceData(groupedUsagesArray)
                groupedServicesData = groupedServicesData.filter((service) => !SHIPPING_LABEL_SERVICE_TYPES.includes(service.serviceType) )
            }

             if (groupedUsagesDataReturnByCarrier){
                groupedServicesData.push(groupedUsagesDataReturnByCarrier)
             }
        }
        else{
            groupedServicesData = groupedBillingServices.map(billingService => {
                let groupedUsagesData = null

                switch(billingService.service_type){
                    case BILLING_SERVICES.STORAGE_BY_LOCATION:
                        groupedUsagesData = generateStorageServiceData(billingService, volumeCategories, useCreditData, null)
                        break
                    case BILLING_SERVICES.STORAGE_BY_PRODUCT:
                        groupedUsagesData = generateStorageServiceData(billingService, volumeCategories, useCreditData, null)
                        break
                    case BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL:
                        groupedUsagesData = generateInternationalShippingLabelServiceData(groupedServicesKey,billingService, useCreditData)
                        break
                    default:
                        groupedUsagesData = generateServiceDataByPriceConfiguration(groupedServicesKey, billingService, shippingPackages, useCreditData, hideNotBilledNorDiscounted)
                }

                servicesTotal += groupedUsagesData.usagesSumTotal
                return groupedUsagesData
            })
        }

        groupedServicesData = groupedServicesData.filter(({usagesSumTotal}) => usagesSumTotal !== 0)

        return {groupedServicesKey: groupedServicesKey, groupedServices: groupedServicesData, total: servicesTotal}
    }

    const filterServices = (billingServices, validServiceTypes) => {
        return billingServices.filter(billingService => (
            validServiceTypes.some(validServiceType => validServiceType === billingService.service_type)
        ))
    }

    const orderGroupedServices = (groupedServices, groupedServicesKey) => {
        let orderedServices = null

        switch(groupedServicesKey){
            case GROUPED_SERVICE_KEYS.SHIPPING_LABEL:
                orderedServices = groupedServices.sort((serviceA, serviceB) => {
                    const isShippingLabelA = serviceA.service_type === BILLING_SERVICES.SHIPPING_LABEL ? true : false
                    const isShippingLabelB = serviceB.service_type === BILLING_SERVICES.SHIPPING_LABEL ? true : false

                    const isExtendedZoneA = serviceA.service_type === BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE ? true : false
                    const isExtendedZoneB = serviceB.service_type === BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE ? true : false

                    const areSameServiceTypeCategory = serviceA.service_type_category === serviceB.service_type_category ? true : false

                    if(isExtendedZoneA && isShippingLabelB && areSameServiceTypeCategory){
                        return 0
                    }
                    else if(isExtendedZoneB && isShippingLabelA && areSameServiceTypeCategory){
                        return 1
                    }
                    else{
                        return -1
                    }
                })
                break

            default:
                orderedServices = groupedServices
        }

        return orderedServices
    }

    const generateTableData = () => {
        if(!storeBillingServices || !storeShippingPackages || !warehouseVolumeCategories){ return null }

        const storeBillingServicesHasStorage = storeBillingServices.some(({service_type}) => service_type === BILLING_SERVICES.STORAGE_BY_LOCATION || service_type === BILLING_SERVICES.STORAGE_BY_PRODUCT) ? true : false
        const currentStorageServiceUsagesHasStorage = (currentStorageServiceUsages?.service_type === BILLING_SERVICES.STORAGE_BY_LOCATION || currentStorageServiceUsages?.service_type === BILLING_SERVICES.STORAGE_BY_PRODUCT) ? true : false

        if(storeBillingServicesHasStorage && currentStorageServiceUsagesHasStorage){
            throw new Error("Duplicate data sources found for storage service usages")
        }

        const validServiceTypes = Object.values(BILLING_SERVICES)

        storeBillingServices.forEach(({service_type, service_type_category}) => {
            const isValidServiceType = validServiceTypes?.some(serviceType => serviceType === service_type)
            if(!isValidServiceType){
                throw new Error("Invalid service type found")
            }

            const validServiceTypeCategories = BILLING_SERVICE_TYPE_CATEGORIES[service_type]
            const isValidServiceTypeCategory = validServiceTypeCategories?.some(serviceTypeCategory => serviceTypeCategory === service_type_category)
            if(!isValidServiceTypeCategory){
                throw new Error("Invalid service type category found")
            }
        })

        const data = []

        Object.keys(GROUPED_SERVICE_TYPES).forEach(serviceKey => {
            const useCreditData = serviceKey === "CREDIT" ? true : false
            const groupedServices = useCreditData ? storeBillingServices : filterServices(storeBillingServices, GROUPED_SERVICE_TYPES[serviceKey])
            const orderedGroupedServices = orderGroupedServices(groupedServices, serviceKey)
            const groupedServicesTableData = generateGroupedServicesTableData(orderedGroupedServices, serviceKey, storeShippingPackages, warehouseVolumeCategories, useCreditData, currentStorageServiceUsages)

            if(groupedServicesTableData.total !== 0){
                data.push(groupedServicesTableData)
            }
        })

        return data
    }

    const getCurrencyCode = () => {
        let currencyCode = null
        let storeBillingServicesCurrency = null
        let currentStorageServiceUsagesCurrency = null

        if(storeBillingServices?.length > 0){
            const isSameCurrency = storeBillingServices.every((billingService, index, allBillingServices) => (
                allBillingServices.find(({currency}) => currency !== billingService.currency) ? false : true
            ))

            if(!isSameCurrency){
                throw new Error("Different currencies found")
            }

            storeBillingServicesCurrency = storeBillingServices[0].currency
        }

        if(currentStorageServiceUsages?.length > 0){
            const isSameCurrency = currentStorageServiceUsages.every((billingService, index, allBillingServices) => (
                allBillingServices.find(({currency}) => currency !== billingService.currency) ? false : true
            ))

            if(!isSameCurrency){
                throw new Error("Different currencies found")
            }

            currentStorageServiceUsagesCurrency = currentStorageServiceUsages[0].currency
        }

        if(storeBillingServices?.length > 0 && currentStorageServiceUsages?.length > 0){
            if(storeBillingServicesCurrency !== currentStorageServiceUsagesCurrency){
                throw new Error("Different currencies found")
            }
            currencyCode = storeBillingServicesCurrency
        }
        else if(storeBillingServices?.length > 0){
            currencyCode = storeBillingServicesCurrency
        }
        else if(currentStorageServiceUsages?.length > 0){
            currencyCode = currentStorageServiceUsagesCurrency
        }

        return currencyCode
    }

    const handleOnShowSections = (groupedServicesKey) => {
        const newShowSections = {...showSections}
        newShowSections[groupedServicesKey] = !newShowSections[groupedServicesKey]
        setShowSections(newShowSections)
    }

    const checkIsLastServiceUsage = (groupedServicesIndex, totalServicesGroups, groupedServiceIndex, totalGroupedServices) => {
        return groupedServiceIndex === (totalGroupedServices - 1) && groupedServicesIndex === (totalServicesGroups - 1)
    }

    const formatQuantity = (numberToFormat) => {
        let roundedNumber = parseFloat(numberToFormat).toFixed(2)
        roundedNumber = parseFloat(roundedNumber)

        const formattedNumber = numberToString(roundedNumber, i18n.language)
        return formattedNumber
    }

    const areAllSectionsHidden = Object.values(showSections).every(showSection => !showSection)
    const tableData = generateTableData()
    const currencyCode = getCurrencyCode()

    return (
        <div className="flex flex-col h-full">
            <div className="bg-white flex py-4 px-4">
                <Button type={"secondary"} onClick={() => onExport()} disabled={exportBusy}>
                    <DownloadIcon className="inline mr-1 h-4 w-4" aria-hidden="true" /> {exportBusy ? i18n.t("billing.statement.exporting_services_summary") : i18n.t("billing.statement.export_services_summary")}  {i18n.t(`billing.summaries_options.SERVICES_SUMMARY`)}   
                </Button>
            </div>
            <table className="w-full border-separate border-spacing-0 h-full table-fixed">
                <thead className="text-left">
                    <tr className="bg-gray-200 sticky top-0">
                        {Object.values(TABLE_HEADERS).map((tableHeader, index) => (
                            <th className={`py-3 ${index === 0 ? "pl-10 w-4/12" : ""}`} key={`service-summary-header-${tableHeader}`}>
                                {areAllSectionsHidden === true && tableHeader !== TABLE_HEADERS.TOTAL && tableHeader !== TABLE_HEADERS.TITLE ? (
                                    <>&nbsp;</>
                                ) : (
                                    <div className={`${tableHeader === TABLE_HEADERS.UNIT_VALUE || tableHeader === TABLE_HEADERS.TOTAL || tableHeader === TABLE_HEADERS.QUANTITY  ? "w-32 text-right" : ""}`}>
                                        {i18n.t(`billing.services_summary_table.headers.${tableHeader}`)}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {tableData?.map(groupedServicesTableData => (
                        <Fragment key={groupedServicesTableData.groupedServicesKey}>
                            <tr className="font-bold text-lg text-gray-800">
                                <td className="p-0">
                                    <div className="mt-5 ml-10 pl-5 py-2 rounded-l-lg bg-gray-200 flex gap-2 items-center cursor-pointer" onClick={() => handleOnShowSections(groupedServicesTableData.groupedServicesKey)}>
                                        <div>{i18n.t(`billing.services_summary_table.tables_titles.${groupedServicesTableData.groupedServicesKey}`)}</div>

                                        <div className="w-4 h-4">
                                            {!showSections[groupedServicesTableData.groupedServicesKey] ? <PlusIcon /> : <MinusIcon />}
                                        </div>

                                    </div>
                                </td>
                                <td className="p-0"><div className="mt-5 py-2 bg-gray-200 cursor-pointer" onClick={() => handleOnShowSections(groupedServicesTableData.groupedServicesKey)}>&nbsp;</div></td>
                                <td className="p-0"><div className="mt-5 py-2 bg-gray-200 cursor-pointer" onClick={() => handleOnShowSections(groupedServicesTableData.groupedServicesKey)}>&nbsp;</div></td>
                                <td className="p-0"><div className="mt-5 py-2 bg-gray-200 cursor-pointer" onClick={() => handleOnShowSections(groupedServicesTableData.groupedServicesKey)}>&nbsp;</div></td>
                                <td className="p-0">
                                    <div className={`mt-5 mr-10 py-2 rounded-r-lg bg-gray-200 cursor-pointer ${groupedServicesTableData.total < 0 ? "text-green-500" : ""}`} onClick={() => handleOnShowSections(groupedServicesTableData.groupedServicesKey)}>
                                        <div className="text-right w-32">
                                            {formatMoney(groupedServicesTableData.total, i18n.language, currencyCode)}
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            {showSections[groupedServicesTableData.groupedServicesKey] === true && groupedServicesTableData.groupedServices.map((groupedService, groupedServicesIndex, {length: totalServicesGroups}) => (
                                <Fragment key={`${groupedServicesTableData.groupedServicesKey}-${groupedService.serviceType}-${groupedServicesIndex}`}>
                                    {groupedService.isCategorized === true && (
                                        <tr className="font-semibold text-gray-800">
                                            <td className="p-0">
                                                <div className="ml-16 py-4 border-b border-b-gray-200">
                                                    <div>
                                                        {groupedService.serviceTitle}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="p-0 border-b border-b-gray-200">&nbsp;</td>
                                            <td className="p-0 border-b border-b-gray-200">&nbsp;</td>
                                            <td className="p-0 border-b border-b-gray-200">
                                                {groupedService.hasSamePriceMode === true && (
                                                    <div className="text-right w-32">
                                                        {formatQuantity(groupedService.usagesSumQuantity)}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="p-0">
                                                <div className={`mr-10 py-4 border-b border-b-gray-200 ${groupedService.isCredit ? "text-green-500" : ""}`}>
                                                    <div className="text-right w-32">
                                                        {formatMoney(groupedService.usagesSumTotal, i18n.language, currencyCode)}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                    {groupedService.groupedUsages.map((groupedServiceUsage, groupedServiceIndex, {length: totalGroupedServices}) => (
                                        <Fragment key={`${groupedServicesTableData.groupedServicesKey}-${groupedServiceUsage.serviceType}-${groupedServicesIndex}-${groupedServiceIndex}`}>
                                            <tr className="text-gray-500">
                                                <td className={`py-0 pr-0 ${groupedService.isCategorized ? "pl-24" : "pl-16"}`}>
                                                    <div className={`py-2 ${checkIsLastServiceUsage(groupedServicesIndex, totalServicesGroups, groupedServiceIndex, totalGroupedServices) ? "" : "border-b border-b-gray-200"}`}>
                                                        <div className={`${groupedService.isCategorized ? "" : "font-semibold text-gray-800"}`}>
                                                            {groupedService.serviceType ===  BILLING_SERVICES.SHIPMENTS_RETURN_BY_CARRIER ? (
                                                                <>
                                                                    { groupedServiceUsage.category == "NONE_APPLIES" ? (
                                                                        <>{groupedServiceUsage.title}</>
                                                                    ):(
                                                                        <>{i18n.t(`billing.services_summary_table.tables_sections.SHIPPING_LABEL.${groupedServiceUsage.category}`)} - {groupedServiceUsage.title}</>
                                                                    )}
                                                                </>
                                                            ):( 
                                                                <>{groupedServiceUsage.title}</>
                                                            )}
                                                           
                                                        </div>
                                                        {groupedServiceUsage.description?.length > 0 && (
                                                            <div className="italic text-gray-300">
                                                                {groupedServiceUsage.description}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className={`p-0 ${checkIsLastServiceUsage(groupedServicesIndex, totalServicesGroups, groupedServiceIndex, totalGroupedServices) ? "" : "border-b border-b-gray-200"}`}>
                                                    {groupedServiceUsage.unit}
                                                </td>
                                                <td className={`p-0 ${checkIsLastServiceUsage(groupedServicesIndex, totalServicesGroups, groupedServiceIndex, totalGroupedServices) ? "" : "border-b border-b-gray-200"}`}>
                                                    {isNaN(groupedServiceUsage.unit_value) ? (
                                                        <div className="italic text-right w-32">
                                                            {i18n.t("billing.services_summary_table.not_available")}
                                                        </div>
                                                    ) : (
                                                        <div className="text-right w-32">
                                                            {formatMoney(groupedServiceUsage.unit_value, i18n.language, currencyCode)}
                                                        </div>
                                                    )}
                                                </td>
                                                <td className={`p-0 ${groupedService.isCategorized ? "" : "font-semibold text-gray-800"} ${checkIsLastServiceUsage(groupedServicesIndex, totalServicesGroups, groupedServiceIndex, totalGroupedServices) ? "" : "border-b border-b-gray-200"}`}>
                                                    <div className="text-right w-32">
                                                        {formatQuantity(groupedServiceUsage.quantity)}
                                                    </div>
                                                </td>
                                                <td className={`p-0 ${groupedService.isCategorized ? "" : "font-semibold text-gray-800"}`}>
                                                    <div className={`h-full mr-10 flex items-center ${groupedService.isCredit ? "text-green-500" : ""} ${checkIsLastServiceUsage(groupedServicesIndex, totalServicesGroups, groupedServiceIndex, totalGroupedServices) ? "" : "border-b border-b-gray-200"}`}>
                                                        <div className="text-right w-32">
                                                            {formatMoney(groupedServiceUsage.total, i18n.language, currencyCode)}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </table>

            {(!tableData || tableData.length === 0) && (
                <div className="flex justify-center text-gray-800">
                    {i18n.t("billing.services_summary_table.no_data")}
                </div>
            )}
        </div>
    )
}

export default StoreBillingServicesSummary