import { useContext, useState, Fragment, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from "@heroicons/react/outline";
import ReactDatePicker from "react-datepicker"
import moment from "moment-timezone"
import { useQuery } from "react-query"
import { es, enUS, pt } from "date-fns/locale"

import { exportInventory, exportProductStockHistories, fetchAvailableProductStockHistoryDates} from "../services"
import { UserContext } from "../hooks/UserContext"
import { Notification } from "./Notification"
import { notificationTypes } from "./Notification"
import { Button } from "./Button"
import { ButtonLoader } from "./ButtonLoader"
import { getInventoryExport } from "../services/productServices"

const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 60 // 60 minutes

export const DownloadInventory = () => {
    const { i18n } = useTranslation()
    const { user } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(false)
    const [showDateDialog, setShowDateDialog] = useState(false)
    const [notification, setNotification] = useState({type: notificationTypes.SUCCESS, show: false, title: ""})

    const [inventoryDate, setInventoryDate] = useState(false)
    const [availableDates, setAvailableDates] = useState([])

    const DOWNLOAD_INVENTORY_OPTIONS = {
        CURRENT_INVENTORY: "CURRENT_INVENTORY",
        PREVIOUS_INVENTORY: "PREVIOUS_INVENTORY"
    }

    const {
        isLoading: availableStockHistoryDatesIsLoading,
        data: availableStockHistoryDatesData
    } = useQuery(['first_stock_history', user.current_store.id], ()=>fetchAvailableProductStockHistoryDates(user.current_store.id), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME
    })
    
    const watchExportProgress = function (job_id) {
        const intervalId = setInterval(async () => {
            try {
                const { progress_percentage: progressPercentage, download_url: downloadUrl } = await getInventoryExport(job_id)
                if (progressPercentage === 100) {
                    const link = document.createElement('a')
                    link.href = process.env.REACT_APP_PLATFORM_URL + downloadUrl
                    link.download = ''
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    setIsLoading(false)
                    clearInterval(intervalId)
                    setNotification({
                        type: notificationTypes.SUCCESS,
                        show: true,
                        title: i18n.t("inventory.listProducts.download_inventory_success")
                    })
                }
            } catch (error) {
                console.error('Error fetching export progress or downloading file:', error)
                setIsLoading(false)
                clearInterval(intervalId)
                setNotification({
                    type: notificationTypes.ERROR,
                    show: true,
                    title: i18n.t("inventory.listProducts.download_inventory_error")
                })
            }
        }, 1000)
    }
    

    const downloadInventory = async (selectedOptionId) => {
        let res = null
        let filename = null

        setIsLoading(true)

        try {
            if(selectedOptionId === DOWNLOAD_INVENTORY_OPTIONS.CURRENT_INVENTORY){
                window.analytics.track('Inventory - Downloads Inventory Export')

                res = await exportInventory(user.current_store.id)

                if(res.job_id){
                    watchExportProgress(res.job_id)
                    return
                }

                const today = new Date()
                filename = 'inventory-'+today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+'.csv'

            } else if (selectedOptionId === DOWNLOAD_INVENTORY_OPTIONS.PREVIOUS_INVENTORY){
                window.analytics.track('Inventory - Downloaded Inventory History CSV')

                setShowDateDialog(false)

                const timeZone = user.current_store.warehouses[0].time_zone

                const selectedYear = inventoryDate.getFullYear()
                const selectedMonth = (inventoryDate.getMonth() + 1).toString().padStart(2, "0")
                const selectedDate = inventoryDate.getDate().toString().padStart(2, "0")

                const parsedSelectedOnlyDate = `${selectedYear}-${selectedMonth}-${selectedDate}`

                const selectedDateStartFormatted = moment.tz(parsedSelectedOnlyDate, timeZone).endOf("day").utc().format("YYYY-MM-DD")
                const selectedDateEndFormatted = moment.tz(parsedSelectedOnlyDate, timeZone).endOf("day").utc().format("YYYY-MM-DD")

                const params = {from_date: selectedDateStartFormatted, to_date: selectedDateEndFormatted}

                res = await exportProductStockHistories(user.current_store.id, params)

                filename = 'inventory'
            } else {
                throw new Error("Unknown download inventory option")
            }

            const data = new Blob([res], {type: 'text/csv'});
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');

            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            tempLink.click();

            setNotification({type: notificationTypes.SUCCESS, show: true, title: i18n.t("inventory.listProducts.download_inventory_success")})
        } catch(error){
            setNotification({type: notificationTypes.ERROR, show: true, title: i18n.t("inventory.listProducts.download_inventory_error")})
        }

        setIsLoading(false)
    }

    const handleOnClickDownloadOption = (selectedOptionId) => {
        if(selectedOptionId === DOWNLOAD_INVENTORY_OPTIONS.CURRENT_INVENTORY){
            downloadInventory(selectedOptionId)
        } else if(selectedOptionId === DOWNLOAD_INVENTORY_OPTIONS.PREVIOUS_INVENTORY){
            setShowDateDialog(true)
        }
    }

    const handleOnSelectedInventoryDate = (selectedInventoryDate) => {
        let inventoryDateToUpdate = null

        if(selectedInventoryDate){
            const timeZone = user.current_store.warehouses[0].time_zone

            const inventoryYear = selectedInventoryDate.getFullYear()
            const inventoryMonth = (selectedInventoryDate.getMonth() + 1).toString().padStart(2, "0")
            const inventoryDay = selectedInventoryDate.getDate().toString().padStart(2, "0")

            const inventoryDateWithoutTime = `${inventoryYear}-${inventoryMonth}-${inventoryDay}`
            const formattedInventoryDate = moment(inventoryDateWithoutTime).tz(timeZone).startOf("day").format("YYYY-MM-DD HH:mm:ss")

            inventoryDateToUpdate = new Date(formattedInventoryDate)
        }

        setInventoryDate(inventoryDateToUpdate)
    }

    useEffect(() => {
        if(availableStockHistoryDatesData?.available_dates.length > 0){
            const timeZone = user.current_store.warehouses[0].time_zone

            const newAvailableDates = availableStockHistoryDatesData.available_dates.map(availableDate => {
                const parsedDate = moment.utc(availableDate).tz(timeZone).startOf("day").format("YYYY-MM-DD HH:mm:ss")
                return (new Date(parsedDate))
            })

            setAvailableDates(newAvailableDates)
        } else{
            setAvailableDates([])
        }
    }, [availableStockHistoryDatesData, user])

    return (
        <div>
            <div className="flex gap-7">
                <ButtonLoader
                    className="whitespace-nowrap"
                    type={"secondary"}
                    loading={isLoading || availableStockHistoryDatesIsLoading ? true : false}
                    disabled={isLoading || availableStockHistoryDatesIsLoading ? true : false}
                    onClick={() => handleOnClickDownloadOption(DOWNLOAD_INVENTORY_OPTIONS.CURRENT_INVENTORY)}
                >
                    {i18n.t("inventory.listProducts.download_inventory")}
                </ButtonLoader>

                <ButtonLoader
                    className="whitespace-nowrap"
                    type={"secondary"}
                    loading={isLoading || availableStockHistoryDatesIsLoading ? true : false}
                    disabled={isLoading || availableStockHistoryDatesIsLoading ? true : false}
                    onClick={() => handleOnClickDownloadOption(DOWNLOAD_INVENTORY_OPTIONS.PREVIOUS_INVENTORY)}
                >
                    {i18n.t("inventory.listProducts.download_previous_inventory")}
                </ButtonLoader>
            </div>

            <Notification
                show={notification.show}
                type={notification.type}
                title={notification.title}
                setShow={showNotification => setNotification({...notification, show: showNotification})}
            />

            <Transition.Root show={showDateDialog} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0"
                    initialFocus={null}
                    open={showDateDialog}
                    onClose={setShowDateDialog}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">

                                <div className="flex justify-between">
                                    <div className="text-xl font-semibold text-gray-700">
                                        {i18n.t("inventory.listProducts.download_inventory")}
                                    </div>

                                    <XIcon className="flex-shrink-0 cursor-pointer text-gray-400 h-5 w-5"
                                        onClick={() => setShowDateDialog(false)}
                                    />
                                </div>

                                <div className="h-0.5 w-full bg-gray-400 my-5"></div>

                                <label className="text-gray-700" htmlFor="inventory-date-picker">
                                    {i18n.t("inventory.listProducts.download_inventory_select_date")}
                                </label>

                                <ReactDatePicker
                                    id="inventory-date-picker"
                                    className={`mt-2 border rounded border-gray-300 w-full z-50 ${availableDates?.length > 0 ? "placeholder:text-gray-300 cursor-pointer" : "placeholder:text-red-200 cursor-not-allowed"}`}
                                    selected={inventoryDate}
                                    placeholderText={availableDates?.length > 0 ? i18n.t("inventory.listProducts.select_inventory_date") : i18n.t("inventory.listProducts.no_previous_inventory")}
                                    dateFormat="dd/MM/yy"
                                    onChange={handleOnSelectedInventoryDate}
                                    disabled={availableDates?.length > 0 ? false : true}
                                    includeDates={availableDates}
                                    locale={i18n.language === "es" ? es : i18n.language === "pt" ? pt :enUS}
                                />

                                <div className="flex gap-5 items-stretch mt-5">
                                    <Button type="secondary" onClick={() => setShowDateDialog(false)} className="w-full">
                                        {i18n.t("inventory.listProducts.download_inventory_cancel_button")}
                                    </Button>

                                    <Button
                                        type="primary"
                                        onClick={() => downloadInventory(DOWNLOAD_INVENTORY_OPTIONS.PREVIOUS_INVENTORY)}
                                        className="w-full"
                                        disabled={!inventoryDate}
                                    >
                                        {i18n.t("inventory.listProducts.download_inventory_download_button")}
                                    </Button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}
