import { useContext, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from "react-router-dom"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { UserContext } from "../../hooks/UserContext"
import { useSelector } from "react-redux"
import { fetchStoreReports, updateStoreReport } from "../../services/storeServices"
import { Loader } from "../../components/Loader"
import { SETTINGS_AUTOMATIC_REPORT_ADD, SETTINGS_AUTOMATIC_REPORT_EDIT, STORE_PATH } from "../../navigation/constants"
import { ActionMenu, NewTable, Notification } from "../../components"
import { setStoresListPageSize } from "../../redux/pageSizeSlice"
import { useDispatch } from "react-redux"
import { getConfigurations } from "../../services/storeServices"

export const AutomaticReportsContainer = ({ setAutomaticReports }) => {
    const { t, i18n } = useTranslation()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const pageSize = useSelector((state) => state?.pageSize?.ordersList)
    const { user } = useContext(UserContext)
    const dispatch = useDispatch()
    const history = useHistory()

    const initialSearchParams = {
        page: 1,
        store_id: user.current_store?.id,
        per_page: pageSize,
        order_by: 'created_at',
        order: 'desc'
    }
    const [searchParams, setSearchParams] = useState(initialSearchParams)

    const {
        isLoading,
        isFetching,
        isError,
        error,
        refetch,
        data,
        isPreviousData
    } = useQuery(['automatic_reports', initialSearchParams], () => fetchStoreReports(initialSearchParams), {
        keepPreviousData: true
    })

    const {
        isLoading: emailRecipientsIsLoading,
        isFetching: emailRecipientsIsFetching,
        isError: emailRecipientsIsError,
        error: emailRecipientsError,
        refetch: emailRecipientsRefetch,
        data: emailRecipientsData,
        isPreviousData: emailRecipientsIsPreviousData
    } = useQuery(['billing_statement_email_recipients', user.current_store?.id, "billing_statement_email_recipients"], () => getConfigurations(user.current_store?.id, "billing_statement_email_recipients"), {
        keepPreviousData: true
    })

    const reportNamesAndTypes = [
        {
            report_type: "inventory_detail",
            category: "INVENTORY",
            translation: i18n.t("settings.automatic_reports.report_names.inventory_detail")
        },
        {
            report_type: "lots_detail",
            category: "INVENTORY",
            translation: i18n.t("settings.automatic_reports.report_names.lots_detail")
        },
        {
            report_type: "inventory_kardex",
            category: "INVENTORY",
            translation: i18n.t("settings.automatic_reports.report_names.inventory_kardex")
        },
        {
            report_type: "shipped_products",
            category: "SALES",
            translation: i18n.t("settings.automatic_reports.report_names.shipped_products")
        },
        {
            report_type: "shipped_orders_detail",
            category: "ORDERS",
            translation: i18n.t("settings.automatic_reports.report_names.shipped_orders_detail")
        },
        {
            report_type: "backorder_products",
            category: "INVENTORY",
            translation: i18n.t("settings.automatic_reports.report_names.backorder_products")
        },
        {
            report_type: "returned_products",
            category: "RETURNS",
            translation: i18n.t("settings.automatic_reports.report_names.returned_products")
        },
        {
            report_type: "claims_detail",
            category: "CLAIMS",
            translation: i18n.t("settings.automatic_reports.report_names.claims_detail")
        },
        {
            report_type: "replenishments_detail",
            category: "REPLENISHMENTS",
            translation: i18n.t("settings.automatic_reports.report_names.replenishments_detail")
        },
        {
            report_type: "return_invoices_detail",
            category: "INVOICES",
            translation: i18n.t("settings.automatic_reports.report_names.return_invoices_detail")
        },
        {
            report_type: "order_invoices_detail",
            category: "INVOICES",
            translation: i18n.t("settings.automatic_reports.report_names.order_invoices_detail")
        },
        {
            report_type: "replenishment_invoices_detail",
            category: "INVOICES",
            translation: i18n.t("settings.automatic_reports.report_names.replenishment_invoices_detail")
        },
        {
            report_type: "billing_statement_email_recipients",
            category: "BILLING",
            translation: i18n.t("settings.automatic_reports.report_names.billing_statement_email_recipients")
        }
    ]

    const STORE_AUTOMATIC_REPORTS_CATEGORIES = [
        {
            type: 'INVENTORY',
            label: i18n.t("settings.automatic_reports.report_categories.INVENTORY")
        }, {
            type: 'SALES',
            label: i18n.t("settings.automatic_reports.report_categories.SALES")
        }, {
            type: 'ORDERS',
            label: i18n.t("settings.automatic_reports.report_categories.ORDERS")
        }, {
            type: 'RETURNS',
            label: i18n.t("settings.automatic_reports.report_categories.RETURNS")
        }, {
            type: 'CLAIMS',
            label: i18n.t("settings.automatic_reports.report_categories.CLAIMS")
        }, {
            type: 'REPLENISHMENTS',
            label: i18n.t("settings.automatic_reports.report_categories.REPLENISHMENTS")
        }, {
            type: 'INVOICES',
            label: i18n.t("settings.automatic_reports.report_categories.INVOICES")
        }, {
            type: 'BILLING',
            label: i18n.t("settings.automatic_reports.report_categories.BILLING")
        }
    ]

    const reportCategoryFilters = STORE_AUTOMATIC_REPORTS_CATEGORIES.filter(
        (rule) => rule.filterable !== false).map((rule) => {
            return rule.filterable === false
                ? null : { id: rule.type, label: rule.label }
        }
        )

    const handlerOnClickStatus = (report) => {

        setAutomaticReports((prevState) => ({
            reportId: report?.id,
            reportTemplate: report, // Only used when report is not set yet
            tabContentAutomaticReports: report?.id ? '1' : '2'
        }))

        if (report?.id) {
            let updated_path = (storePath + SETTINGS_AUTOMATIC_REPORT_EDIT).replace(":report_id", report.id)
            history.push(updated_path)
        } else {
            history.push(storePath + SETTINGS_AUTOMATIC_REPORT_ADD)
        }
    }

    const filtersInitialData = [
        {
            key: "report_type",
            placeholder: "settings.automatic_reports.filters.name",
            label: "settings.automatic_reports.filters.name",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "category",
            placeholder: "settings.automatic_reports.filters.category",
            label: "settings.automatic_reports.filters.category",
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                options: reportCategoryFilters,
            },
        },
        {
            key: "recipients_emails",
            placeholder: "settings.automatic_reports.filters.recipients",
            label: "settings.automatic_reports.filters.recipients",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        }
    ]
    const [filtersData, setFiltersData] = useState(filtersInitialData)

    const [showNotification, setShowNotification] = useState(false)
    const [errorNotification, setErrorNotification] = useState(false)

    const buildReportsData = () => {
        const reportTypeMap = new Map()
        data?.forEach((data) => {
            if (user.current_store?.warehouses[0].country !== "BR" && data.report_type.includes("invoices")) {
                return
            }
            reportTypeMap.set(data.report_type, data)
        })

        // Include billing_statement_email_recipients, billing_statement_email_recipients is not initially included in automatic_reports
        if(user.current_store?.warehouses[0].country === "MX"){
            const statement_email_recipients = {
                report_type: "billing_statement_email_recipients",
                enabled: true,
                category: "BILLING",
                frequency: ["STATEMENT"],
                recipients_emails: emailRecipientsData ? emailRecipientsData : []
            }

            reportTypeMap.set(statement_email_recipients.report_type,statement_email_recipients )
        }

        const result = reportNamesAndTypes
            .filter((report) => {
                return !(
                    user.current_store?.warehouses[0].country !== "BR" &&
                    report.report_type.includes("invoices")
                )
            })
            .filter(report => {
                return user.current_store?.warehouses[0].country !== "MX" && report.report_type.includes("billing_statement_email_recipients") ? false : true
            })
            .map((report) => {
                const dataObject = reportTypeMap.get(report.report_type)
                if (dataObject) {
                    return {
                        ...dataObject,
                    }
                } else {
                    return {
                        id: null,
                        store_id: user.current_store?.id,
                        enabled: false,
                        ...report,
                    }
                }
            })

        return result
    }

    const applyFilters = (reportsData) => {
        const { report_type, category, recipients_emails } = searchParams
        const filteredData = reportsData.filter((report) => {

            if (report_type && report_type.length > 0) {
                const matchingTranslations = reportNamesAndTypes.filter((element) => {
                    const translation = element.translation.toLowerCase()
                    return translation.includes(report_type.toLowerCase())
                })
                if (
                    matchingTranslations.length > 0 &&
                    !matchingTranslations.some((translation) => translation.report_type === report?.report_type)
                ) {
                    return false
                }
                if (matchingTranslations.length === 0)
                    return false
            }
            if (category && category.length > 0 && !category.some((cat) => cat === report?.category)) {
                return false
            }

            if (recipients_emails && recipients_emails.length > 0 && !report?.recipients_emails?.some((recipients_email) => recipients_email.includes(recipients_emails))) {
                return false
            }

            return true
        })

        return filteredData
    }

    const handleReportDeliveryStatus = (status, report) => {
        window.analytics.track('Automatic Reports - Starts Editing', { report_id: report?.id, name: report?.report_type })
        updateStoreReport(report?.id, {
            enabled: status,
            frequency: report?.frequency,
            recipients_emails: report?.recipients_emails,
            sheet_headers: report?.sheet_headers
        }).then((response) => {
            setShowNotification(true)
            refetch()
        }).catch((e) => {
            setErrorNotification(true)
        })
    }

    const getMenuOptions = (report) => {

        const options = [
            {
                title: i18n.t("settings.automatic_reports.report_details.report_buttons.show_details"),
                clickHandler: () => handlerOnClickStatus(report),
            }
        ]

        if (report?.id && report?.report_type !== "billing_statement_email_recipients") {
            if (report.enabled) {
                options.push({
                    title: i18n.t("settings.automatic_reports.report_details.report_buttons.deactivate"),
                    clickHandler: () =>
                        handleReportDeliveryStatus(false, report)
                })
            } else {
                options.push({
                    title: i18n.t("settings.automatic_reports.report_details.report_buttons.activate"),
                    clickHandler: () =>
                        handleReportDeliveryStatus(true, report)
                })
            }
        }
        return options
    }

    const memoizedTableData = useMemo(() => {
        const reportsData = buildReportsData()
        const filteredData = applyFilters(reportsData)
        filteredData.sort((a, b) => {
            if(a.report_type === "billing_statement_email_recipients"){
                return -1
            }

            const reportATranslatedName = i18n.t(`settings.automatic_reports.report_names.${a.report_type}`)
            const reportBTranslatedName = i18n.t(`settings.automatic_reports.report_names.${b.report_type}`)
            return reportATranslatedName.localeCompare(reportBTranslatedName)
        })
        return filteredData.map((report) => {
            return {
                object: report,
                report_type: <div className="mt-1.5 max-w-[9rem]">
                    <span className="whitespace-normal">
                        {i18n.t(`settings.automatic_reports.report_names.${report.report_type}`)}
                    </span>
                </div>,
                enabled: <div className={`mt-1.5 max-w-[5rem] font-normal ${report?.enabled ? "text-green-500" : "text-gray-500"}`}>
                    <span className="whitespace-normal">
                        {i18n.t(`settings.automatic_reports.report_details.${report.enabled}`)}
                    </span>
                </div>,
                category: <div className="mt-1.5 max-w-[5rem]">
                    <span className="whitespace-normal">
                        {i18n.t(`settings.automatic_reports.report_categories.${report.category}`)}
                    </span>
                </div>,
                shipping_frequency: <div className={`${report?.frequency?.length > 0 ? "mt-1.5 max-w-[12rem]" : "mt-1.5 italic text-gray-400 max-w-[12rem]"}`}>
                    <span className="whitespace-normal">
                        {report.frequency && report?.frequency?.length > 0 ? (
                            report.frequency.map((freq) =>
                                i18n.t(`settings.automatic_reports.report_frequency.${freq}`)
                            ).join(", ")
                        ) : (
                            i18n.t("settings.automatic_reports.not_defined")
                        )}
                    </span>
                </div>,
                recipients_emails:
                    <div className={`${report.recipients_emails?.length > 0 ? "mt-1.5 max-w-[12rem]" : "mt-1.5 italic text-gray-400 max-w-[12rem]"}`}>
                        <span className="whitespace-normal">
                            {report.recipients_emails && report.recipients_emails?.length > 0 ? (
                                <>
                                    {report.recipients_emails[0]}
                                    {report.recipients_emails.length > 1 && (
                                        <span>
                                            {" " + i18n.t("settings.automatic_reports.and")} {report.recipients_emails.length - 1} {i18n.t("settings.automatic_reports.more")}
                                        </span>
                                    )}
                                </>
                            ) : (
                                i18n.t("settings.automatic_reports.not_defined")
                            )}
                        </span>
                    </div>,
                actions: <ActionMenu className="float-right" items={getMenuOptions(report)} />
            }
        })
    }, [data, searchParams, t, emailRecipientsData])

    const memoizedColumns = useMemo(() => {
        if (!data) return []

        return [
            {
                Header: "settings.automatic_reports.report_table_columns.name",
                accessor: "report_type", // accessor is the "key" in the data
                disableSortBy: true,
            },
            {
                Header: "settings.automatic_reports.report_table_columns.status",
                accessor: "enabled", // accessor is the "key" in the data
                disableSortBy: true,
            },
            {
                Header: "settings.automatic_reports.report_table_columns.category",
                accessor: "category", // accessor is the "key" in the data
                disableSortBy: true,
            },
            {
                Header: "settings.automatic_reports.report_table_columns.frequency",
                accessor: "shipping_frequency", // accessor is the "key" in the data
                disableSortBy: true,
            },
            {
                Header: "settings.automatic_reports.report_table_columns.recipients",
                accessor: "recipients_emails", // accessor is the "key" in the data
                disableSortBy: true,
            },
            {
                Header: "",
                accessor: "actions",
                disableSortBy: true,
            }
        ]
    }, [data, searchParams, t])

    const updateFiltersData = (updatedData, filter, override = false) => {
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter)
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
        let value = updatedData.value
        if (!value) value = {}
        setSearchParams((prev) => ({ ...prev, [filter]: value, page: 1 }))
    }


    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const handleResetFilters = () => {
        setSearchParams(initialSearchParams)
    }

    const isLoadingData = () => {
        return (!isPreviousData && isLoading) || (!emailRecipientsIsPreviousData && emailRecipientsIsLoading) ? true : false
    }

    if (isLoadingData())
        return <Loader show={true} />

    if (!isLoadingData() && isError)
        return <>Error: {error.message}</>

    if (!isLoadingData() && emailRecipientsIsError)
        return <>Error: {emailRecipientsError.message}</>

    return (
        <div className='relative p-10'>
            <div className="space-y-1">
                <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>
                    {t("settings.automatic_reports.title")}
                </p>
                <div className="flex justify-between mt-5">
                    <p className="text-lg text-gray-500">
                        {t("settings.automatic_reports.sub_title")}
                    </p>

                </div>
            </div>

            <div className="mt-7 w-full relative">
                {
                    !isPreviousData && isLoading ? (
                        <><Loader show={true}></Loader></>
                    ) : isError ? (
                        <>Error: {error.message}</>
                    ) :
                        (
                            <>
                                <NewTable
                                    data={memoizedTableData}
                                    columns={memoizedColumns}
                                    showLoader={isFetching && isPreviousData}
                                    isFetching={isFetching}
                                    emptyTableText={i18n.t("settings.automatic_reports.no_reports_to_show")}
                                    showPaginationOnHeader={false}
                                    paginationMeta={data?.meta}
                                    onPaginationChange={(page) => {
                                        setSearchParams((prev) => ({ ...prev, page: page }))
                                    }}
                                    onPageSizeChange={(page) => {
                                        setSearchParams((prev) => ({ ...prev, per_page: page.id, page: 1 }))
                                        dispatch(setStoresListPageSize(page.id))
                                    }}
                                    rowProps={row => ({
                                        onClick: (e) => handlerOnClickStatus(row.original?.object)
                                    })}
                                    onFilterChanged={onFilterChanged}
                                    handleResetFilters={handleResetFilters}
                                    filtersData={filtersData}
                                    hasExport={false}
                                />
                            </>
                        )}

            </div>

            <Notification show={showNotification}
                setShow={setShowNotification}
                type="success"
                title={i18n.t("settings.automatic_reports.report_details.report_notifications.status_done")} />
            <Notification
                show={errorNotification}
                setShow={setErrorNotification}
                type="error"
                title={i18n.t("settings.automatic_reports.report_details.report_notifications.error")} />
        </div>
    )
}
