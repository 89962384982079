export const BILLING_SERVICES = {
    CUSTOM: "CUSTOM",
    CUSTOM_RECURRING: "CUSTOM_RECURRING",
    STORAGE_BY_LOCATION: "STORAGE_BY_LOCATION",
    STORAGE_BY_PRODUCT: "STORAGE_BY_PRODUCT",
    SHIPPING_LABEL: "SHIPPING_LABEL",
    SHIPPING_LABEL_EXTENDED_ZONE: "SHIPPING_LABEL_EXTENDED_ZONE",
    INTERNATIONAL_SHIPPING_LABEL: "INTERNATIONAL_SHIPPING_LABEL",
    SHIPPING_LABEL_PICKUP: "SHIPPING_LABEL_PICKUP",
    RETURN_ORDER: "RETURN_ORDER",
    RETURN_ORDER_LOTS: "RETURN_ORDER_LOTS",
    RETURN_SHIPPING_LABEL: "RETURN_SHIPPING_LABEL",
    PICK_AND_PACK: "PICK_AND_PACK",
    PICK_AND_PACK_INSERT: "PICK_AND_PACK_INSERT",
    PICK_AND_PACK_SERIAL_NUMBER: "PICK_AND_PACK_SERIAL_NUMBER",
    PICK_AND_PACK_FRAGILE: "PICK_AND_PACK_FRAGILE",
    PICK_AND_PACK_PACKAGE: "PICK_AND_PACK_PACKAGE",
    REPLENISHMENT: "REPLENISHMENT",
    REPLENISHMENT_LOTS: "REPLENISHMENT_LOTS",
    CLAIM: "CLAIM",
    WORK_ORDER: "WORK_ORDER",
    UNIDENTIFIED_RETURN: "UNIDENTIFIED_RETURN",
    SHIPMENTS_RETURN_BY_CARRIER: "SHIPMENTS_RETURN_BY_CARRIER",
    DIFAL: "DIFAL",
    SHIPPING_INSURANCE: "SHIPPING_INSURANCE",
    PACKING_MATERIAL: "PACKING_MATERIAL"
}

export const RETURN_BY_CARRIER_LABEL_SERVICES = {
    SHIPPING_LABEL: "SHIPPING_LABEL",
    INTERNATIONAL_SHIPPING_LABEL: "INTERNATIONAL_SHIPPING_LABEL",
    SHIPPING_LABEL_PICKUP: "SHIPPING_LABEL_PICKUP",
    SHIPPING_LABEL_EXTENDED_ZONE: "SHIPPING_LABEL_EXTENDED_ZONE",
}

export const RETURN_REPLENISHMENT_SERVICES = {
    RETURN_ORDER: "RETURN_ORDER",
    RETURN_ORDER_LOTS: "RETURN_ORDER_LOTS"
}

export const AVERAGE_SERVICE_GROUPS = {
    pickAndPack: [
        BILLING_SERVICES.PICK_AND_PACK,
        BILLING_SERVICES.PICK_AND_PACK_INSERT,
        BILLING_SERVICES.PICK_AND_PACK_SERIAL_NUMBER
    ],
    package: [
        BILLING_SERVICES.PICK_AND_PACK_FRAGILE,
        BILLING_SERVICES.PICK_AND_PACK_PACKAGE
    ],
    shipping: [
        BILLING_SERVICES.SHIPPING_LABEL,
        BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL,
        BILLING_SERVICES.SHIPPING_LABEL_PICKUP
    ],
    extendedZone: [
        BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE
    ],
    carrierReturn: [
        BILLING_SERVICES.RETURN_ORDER,
        BILLING_SERVICES.RETURN_ORDER_LOTS,
        BILLING_SERVICES.SHIPPING_LABEL,
        BILLING_SERVICES.INTERNATIONAL_SHIPPING_LABEL,
        BILLING_SERVICES.SHIPPING_LABEL_PICKUP,
        BILLING_SERVICES.SHIPPING_LABEL_EXTENDED_ZONE,
    ],
    simpleReturn: [
        BILLING_SERVICES.RETURN_ORDER,
        BILLING_SERVICES.RETURN_ORDER_LOTS,
        BILLING_SERVICES.RETURN_SHIPPING_LABEL
    ]
}

export const AVERAGE_RETURN_TYPES = {
    SIMPLE_RETURN: "SIMPLE_RETURN",
    RETURNED_BY_CARRIER: "RETURNED_BY_CARRIER"
}